import request from '@/service/lib/request';



export function getList () {
  return request({
    url: '/manager/managers',
    method: 'get'
  });
}
export function getModel (id) {
  return request({
    url: '/manager/manager/' + id,
    method: 'get'
  });
}
export function saveModel (data) {
  return request({
    url: '/manager/manager',
    method: 'post',
    data
  });
}
export function deleteModel (id) {
  return request({
    url: '/manager/manager/' + id,
    method: 'delete'
  });
}