<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">
    <el-card shadow="never" class="border-none">
      <div slot="header">
        <el-row>
          <el-col :span="24">
            <el-button type="primary" @click="dataBinding()">刷新</el-button>
            <el-button type="primary" @click="handleEdit(0)">新增</el-button>
          </el-col>
        </el-row>
      </div>

      <el-table :data="tableData" stripe fit>
        <el-table-column prop="avatar" label="" width="70">
          <template slot-scope="scope">
            <el-avatar size="medium" :src="scope.row.avatar">
              {{scope.row.nickName.substring(0,1)}}
            </el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="账号">
        </el-table-column>
        <el-table-column prop="nickName" label="昵称">
        </el-table-column>
        <el-table-column prop="isLock" label="锁">
          <template slot-scope="scope">
            <el-tag :type="!scope.row.isLock ? 'primary' : 'info'" disable-transitions>{{!scope.row.isLock?'可用':'锁定'}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="最后登录日期">
        </el-table-column>
        <el-table-column prop="ipAddress" label="最后登录IP">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button v-if="!scope.row.isSuper" @click="handleEditModel(scope.row)" type="text">编辑</el-button>
            <el-button v-if="!scope.row.isSuper" @click="handleDeleteModel(scope.row)" type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="margin-t16 text-right">

      </div>

    </el-card>
  </div>
</template>
<script>
import { getList, deleteModel } from '@/service/manager.js';

export default {
  data () {
    return {
      loading: false,
      searchOpen: false,
      tableData: []
    };
  },
  created () {
    this.dataBinding();
  },
  methods: {
    //跳转编辑管理员页
    handleEditModel (row) {
      this.$router.push({ name: 'manager-edit', params: { managerId: row.id } });
    },
    //加载页面数据
    dataBinding () {
      this.loading = true;
      getList().then(res => {
        this.tableData = res;
        this.loading = false;
      });
    },
    //跳转新增管理员页
    handleEdit () {
      this.$router.push({ name: 'manager-edit' });
    },
    //删除管理员
    handleDeleteModel (row) {
      this.loading = true;
      deleteModel(row.id).then(res => {
        if (res === true) {
          this.dataBinding();
        }
        else {
          this.$refs.message("删除操作失败");
        }
        this.loading = false;
      });
    }
  }
};
</script>